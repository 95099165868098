import React from 'react';
import './App.css';

function App() {
  const style = {
    color: "#262626"
  }
  return (
    <div className="App">
      <h3 style={style}>Site em Construção</h3>
      <header className="App-header">
      <img style={{height: '40vmin'}} src="/logo-branco-indivic.png" alt="Logo branco indvic com nome" />
      </header>
      <h3 style={style}>Site em Construção</h3>
    </div>
  );
}

export default App;
